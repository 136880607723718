<template>
  <div style="display: inline-block;">
    <b-btn
      v-b-tooltip.hover
      variant="none"
      title="Visualizar"
      class="btn-none rounded-pill m-0 icone-acao p-0"
      @click="abrirArquivo(gridProps.row)"
    >
      <font-awesome-icon
        :icon="faExternalLinkAlt"
        class="text-primary"
      />
    </b-btn>
    <!-- <b-btn
      variant="none"
      class="btn-none rounded-pill m-0 icone-acao p-0"
      title="Enviar por WhatsApp"
      @click="abrirWhatsApp(gridProps.row)"
    >
      <feather-icon
        icon="MessageCircleIcon"
        class="text-primary"
        size="20"
      />
    </b-btn> -->
    <b-btn
      v-b-tooltip.hover
      title="Observação"
      variant="none"
      class="btn-none rounded-pill m-0 icone-acao p-0"
      @click="openModalObservacao(gridProps.row)"
    >
      <font-awesome-icon
        :icon="faMessage"
        :class="{ 'text-warning': temObservacaoNova, 'text-primary': !temObservacaoNova }"
      />
    </b-btn>
    <b-btn
      v-if="gridProps.row.situacao != 'Motivo'"
      v-b-tooltip.hover
      title="Enviar por e-mail"
      variant="none"
      class="btn-none icone-acao m-0 p-0"
      @click="openModalEnviarCnd()"
    >
      <feather-icon
        icon="SendIcon"
        class="text-primary"
        size="20"
      />
    </b-btn>

    <slot name="acao-custom" />

    <atualizacao-manual
      v-if="attManual"
      :exibir-modal="false"
      :tipo-rotina="`CND ${getTipoCnd()}`"
      :enviar-atualizacao="atualizarCnd"
      @atualizar-grid="$emit('atualizar-grid')"
    />

    <modal-observacao
      ref="modalObservacao"
      v-model="gridProps.row"
      :id-registro="gridProps.row.empresaId"
      :tipo="tipoCnd"
      :api="api"
      :notificar="{ sucesso: notficarSucesso, erro: notificarErro }"
      @hidden="fecharObservacao($event)"
    />

    <envio-email
      ref="modalEnvioEmail"
      :title="`Enviar CND ${gridProps.row.razaoSocial}`"
      :sugestao="gridProps.row.emailEmpresa"
      :sugestao-email-adicional="gridProps.row.emailAdicionalEmpresa"
      @enviarEmail="enviarCnd"
    />

    <visualizar-arquivo
      v-if="mostarArquivo && arquivo.url"
      :url="arquivo.url"
      :observacao="arquivo.observacao"
      @hidden="fecharArquivo()"
    >
      <template v-slot:cabecalho-html>
        <slot
          name="cabecalho"
          :empresa-nome="empresaNome"
        />
      </template>

      <template v-slot:rodape-html>
        <slot
          name="rodape"
          :abrir-arquivo="abrirArquivoPendencia"
          :grid-row="gridProps.row"
          :fechar="fecharArquivo"
        />
      </template>
    </visualizar-arquivo>

    <pendencias
      v-if="mostrarPendencias"
      :empresa-id="situacaoFiscalSelecionada.situacaoFiscalId"
      :empresa-nome="situacaoFiscalSelecionada.razaoSocial"
      :grupos-existentes="situacaoFiscalSelecionada.tiposGrupoPendencias"
      @click-aqui="abrirArquivoPendencia(gridProps.row)"
      @hidden="fecharPendencias()"
    />

    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :titulo-carregamento="'Aguarde!'"
      :descricao="'Estamos atualizando a CND ' + getTipoCnd() + '.'"
    />
  </div>
</template>

  <style lang="scss" scoped>
  .acoes-usuario {
    margin-right: 0.3rem;
    padding: 0.4rem;
  }
  .botoes-acao {
    padding: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  </style>

<script>
import {
  faExternalLinkAlt, faRefresh,
} from '@fortawesome/free-solid-svg-icons'
import {
  faMessage, faPaperPlane,
} from '@fortawesome/free-regular-svg-icons'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import api from '@app/cnd-federal/shared/services/api'

export default {
  components: {
    VisualizarArquivo: () => import('@pilar/components/visualizar-arquivo/VisualizarArquivo.vue'),
    ModalObservacao: () => import('@/app/shared/components/modal-observacao/ModalObservacao.vue'),
    EnvioEmail: () => import('@/app/shared/components/envio-email/EnvioEmail.vue'),
    Pendencias: () => import('@app/situacao-fiscal/shared/components/pendencias/Pendencias.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
    AtualizacaoManual: () => import('@/app/shared/components/botoes-inline/atualizacao-manual/AtualizacaoManual.vue'),
  },
  props: {
    gridProps: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    tipoCnd: {
      type: String,
      default: '',
    },
    attManual: {
      type: Boolean,
      default: false,
    },
    maisInfoButton: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      faExternalLinkAlt,
      faMessage,
      faPaperPlane,
      faRefresh,
      mostarArquivo: false,
      arquivo: {},
      mostrarPendencias: false,
      situacaoFiscalSelecionada: null,
      empresaNome: '',
      isLoading: false,
      temObservacaoNova: false,
    }
  },
  watch: {
    temObservacaoNova(val, oldVal) {
      if (val !== oldVal) {
        this.val = val
      }
    },
  },
  mounted() {
    this.temObservacaoNova = this.gridProps.row.temObservacoesNaoLidas
  },
  methods: {
    getTipoCnd() {
      return this.tipoCnd === 'rfb' ? 'RFB/PGFN' : this.tipoCnd.toUpperCase()
    },
    abrirArquivo(documento) {
      this.api.getURL(documento.id)
        .then(payload => {
          this.arquivo.url = payload.data
          this.empresaNome = documento.razaoSocial
          this.mostarArquivo = true
        })
        .catch(err => {
          this.fecharArquivo()
          this.notificarErro('Não foi possível abrir a visualização de arquivo.', err)
          throw err
        })
    },
    fecharArquivo() {
      this.mostarArquivo = false
      this.arquivo = {}
    },
    notficarSucesso(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-check',
          variant: 'success',
        },
      })
    },
    notificarErro(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-exclamation',
          variant: 'danger',
        },
      })
    },
    // eslint-disable-next-line no-unused-vars
    abrirWhatsApp(row) {
      const url = 'https://wa.me/'
      window.open(url)
    },
    openModalObservacao() {
      this.$refs.modalObservacao.show()
    },
    fecharObservacao(observacaoNova) {
      this.temObservacaoNova = observacaoNova.hasObservacaoNova
    },
    enviarCnd(itens) {
      if (itens.emailsPara.length === 0 && itens.emailsCc === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Informe ao menos um e-mail',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
      } else {
        this.api
          .notificaCnd(this.gridProps.row.razaoSocial, itens.emailsPara, itens.emailsCc, this.gridProps.row.id, itens.mensagensAdicional, itens.definirEmailPadroes)
          .then(() => {
            this.notficarSucesso('Cnd enviada com sucesso')
          })
          .catch(err => {
            this.notificarErro(err.message || 'Não foi possível enviar as notificações.')
            throw err
          })
      }
    },
    openModalEnviarCnd() {
      this.$refs.modalEnvioEmail.$refs.modalEnviarEmail.show()
    },
    temPendencia(row) {
      return row.tiposGrupoPendencias && row.tiposGrupoPendencias.length > 0
    },
    abrirPendencias(linha) {
      if (!this.temPendencia(linha)) {
        this.abrirArquivoFiscal(linha)
      } else if (!this.mostarArquivo && !this.mostrarPendencias) {
        this.situacaoFiscalSelecionada = linha
        this.mostrarPendencias = true
      }
    },
    abrirArquivoPendencia(linha) {
      this.mostarArquivo = false
      this.mostrarPendencias = false
      this.abrirArquivoFiscal(linha)
    },
    abrirArquivoFiscal(linha) {
      if (!this.mostarArquivo && !this.mostrarPendencias) {
        this.situacaoFiscalSelecionada = linha
        api.getURLSituacaoFiscal(this.situacaoFiscalSelecionada.situacaoFiscalId)
          .then(payload => {
            this.arquivo.url = payload.data
            this.mostarArquivo = true
          })
          .catch(err => {
            this.fecharArquivoFiscal()
            this.notificarErro('Não foi possível abrir a visualização de arquivo.', err)
            throw err
          })
      }
    },
    fecharArquivoFiscal() {
      this.mostarArquivo = false
      this.situacaoFiscalSelecionada = null
      this.arquivo = {}
    },
    fecharPendencias() {
      this.mostrarPendencias = false
      this.situacaoFiscalSelecionada = null
    },
    atualizarCnd() {
      return api.updateCnd(this.gridProps.row.empresaId, this.tipoCnd.replace('/', '').toLowerCase())
    },
  },
}
</script>
